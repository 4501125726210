/** @format */
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    editData: {},
  },
  mutations: {
    setEditData(state, data) {
      state.editData = data
    },
  },
  actions: {
    fetchDataList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('/product/list', { params: queryParams })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    productionCreate(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('/product/create', data)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    productionDelete(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete('/product/delete', { params: data })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // 编辑
    productionEdit(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put('/product/edit', data)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}
