<!-- @format -->

<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-form @submit.prevent>
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                <div>
                  <div class="logo-wrapper">
                    <h3 class="text-primary invoice-logo">保单详情</h3>
                  </div>
                </div>

                <div class="invoice-number-date mt-md-0">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="invoice-title">会员ID</h4>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input v-model="formData.create_id" disabled />
                      </b-input-group>
                    </div>
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="invoice-title">会员名称</h4>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input v-model="formData.create_name" disabled />
                      </b-input-group>
                    </div>
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="invoice-title">会员等级</h4>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input v-model="formData.create_level" disabled />
                      </b-input-group>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <b-row class="invoice-spacing mb-0 ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">保单编号:</h6>
                <validation-provider #default="{ errors }" name="保单编号">
                  <b-form-input v-model="formData.number" placeholder="暂无保单号" :disabled="yulandis" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="invoice-spacing mb-0 ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">保单号:</h6>
                <validation-provider #default="{ errors }" name="保单号">
                  <b-form-input v-model="formData.number" placeholder="请输入保单号" :disabled="yulandis" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="invoice-spacing mb-0 ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">产品信息:</h6>
                <validation-provider #default="{ errors }" name="保单号">
                  <b-row>
                    <b-col>
                      <label> 品牌 </label>
                      <b-form-input v-model="formData.product.brand.title" disabled />
                    </b-col>
                    <b-col>
                      <label>产品分类</label>
                      <b-form-input v-model="formData.product.cate.title" disabled />
                    </b-col>
                    <b-col>
                      <label>产品名称</label>
                      <b-form-input v-model="formData.product.title" disabled />
                    </b-col>
                  </b-row>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="invoice-spacing mb-0 ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">被保险人:</h6>
                <validation-provider #default="{ errors }" name="被保险人">
                  <b-form-input v-model="formData.protect_user" placeholder="请输入被保险人" :disabled="yulandis" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row class="invoice-spacing mb-0 ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">保单金额:</h6>
                <validation-provider #default="{ errors }" name="保单金额">
                  <b-form-input v-model="formData.money" placeholder="请输入保单金额" :disabled="yulandis" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="invoice-spacing mb-0 ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">签署地:</h6>
                <validation-provider #default="{ errors }" name="签署地">
                  <b-form-input v-model="formData.sign_city" placeholder="请输入签署地" :disabled="yulandis" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="invoice-spacing mb-0 ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">支付率:</h6>
                <validation-provider #default="{ errors }" name="支付率">
                  <b-form-input v-model="formData.rate" placeholder="请输入支付率" :disabled="yulandis" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="invoice-spacing mb-0 ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">被除率:</h6>
                <validation-provider #default="{ errors }" name="被除率">
                  <b-form-input v-model="formData.except_rate" placeholder="请输入被除率" :disabled="yulandis" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <!--
            <b-row class="invoice-spacing mb-0 ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">付费百分比:</h6>
                <validation-provider #default="{ errors }" name="付费百分比">
                  <b-form-input v-model="formData.fee_rate" placeholder="请输入付费百分比" :disabled="yulandis" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row> -->

            <b-row class="invoice-spacing mb-0 ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">保单TP:</h6>
                <validation-provider #default="{ errors }" name="保单TP">
                  <b-form-input v-model="formData.order_tp" placeholder="请输入保单TP" :disabled="yulandis" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-card-body class="invoice-padding form-item-section">
              <h6 class="mb-1">佣金详情:</h6>
              <div ref="form" class="repeater-form" :style="{ height: trHeight }">
                <b-row v-for="(item, index) in formData.share_rate" :key="index" ref="row" class="pb-2">
                  <b-col cols="12">
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="2"> 会员id </b-col>
                        <b-col cols="12" lg="2"> 会员名称 </b-col>
                        <b-col cols="12" lg="2"> 会员等级 </b-col>
                        <b-col cols="12" lg="2"> 分担率 </b-col>
                        <b-col cols="12" lg="2"> 佣金 </b-col>
                        <b-col cols="12" lg="2"> 到账状态 </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <b-col cols="12" lg="2">
                          <b-form-input v-model="formData.share_rate[index].search_id" type="number" class="mb-2" disabled />
                        </b-col>
                        <b-col cols="12" lg="2">
                          <b-form-input v-model="formData.share_rate[index].create_name" class="mb-2" disabled placeholder="请输入会员id" />
                        </b-col>
                        <b-col cols="12" lg="2">
                          <b-form-input v-model="formData.share_rate[index].create_level" class="mb-2" disabled placeholder="请输入会员id" />
                        </b-col>
                        <b-col cols="12" lg="2">
                          <b-form-input v-model="formData.share_rate[index].share_rate" class="mb-2" placeholder="请输入该会员分担比" :disabled="!formData.share_rate[index].create_name || yulandis" />
                        </b-col>
                        <b-col cols="12" lg="2">
                          <b-form-input v-model="formData.share_rate[index].money" class="mb-2" disabled placeholder="请输入会员id" />
                        </b-col>
                        <b-col cols="12" lg="2">
                          <b-form-input v-model="formData.share_rate[index].stu_name" class="mb-2" disabled placeholder="请输入会员id" />
                        </b-col>
                      </b-row>
                      <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                        <feather-icon size="16" icon="XIcon" class="cursor-pointer" @click="removeItem(index)" />
                      </div>
                      {{ error.text }}
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>

            <b-row class="invoice-spacing mb-0 ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">成交时间:</h6>
                <validation-provider #default="{ errors }" name="成交时间">
                  <b-form-input v-model="formData.deal_time" placeholder="请输入成交时间" disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="invoice-spacing mb-0 ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">生效时间:</h6>
                <validation-provider #default="{ errors }" name="成交时间">
                  <b-form-input v-model="formData.apply_time" placeholder="请输入成交时间" disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="invoice-spacing ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">保单备注:</h6>
                <b-form-textarea v-model="formData.desc" placeholder="请输入保单备注" :disabled="yulandis" />
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </b-col>

      <b-col cols="12" md="4" xl="3" class="invoice-actions mt-md-0 mt-2">
        <b-card>
          <!--          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-75" block @click.prevent="submitAddProduct">确认</b-button>-->
          <!--          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mb-75" block @click="yulandis = !yulandis"> {{ yulandis ? '取消预览' : '预览' }} </b-button>-->
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mb-75" block @click="downLoad"> 下载 </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, getCurrentInstance, computed, nextTick, onMounted, reactive, watch, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ListManagerModule from '@/views/GeneralManagement/ProductManager/List/ListManagerModule'

import { BRow, BCol, BCard, BCardBody, BButton, BForm, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, VBToggle } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axiosIns from '@/libs/axios'
import { useToast } from 'vue-toastification/composition'
import orderModule from '@/views/GeneralManagement/OrderManager/OrderList/OrderManagerList'
// import invoiceStoreModule from '../invoiceStoreModule'
// import InvoiceSidebarAddNewCustomer from '../InvoiceSidebarAddNewCustomer.vue'
import zh from 'flatpickr/dist/l10n'
import { required } from 'vee-validate/dist/rules'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  methods: {
    addNewItemInItemForm() {
      if (this.formData.share_rate.length < 6) {
        this.$refs.form.style.overflow = 'hidden'
        this.formData.share_rate.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
          setTimeout(() => {
            this.$refs.form.style.overflow = null
          }, 350)
        })
      } else {
        this.$bvToast.toast('最多只能添加6个分成比例', {
          title: '提示',
          variant: 'warning',
          solid: true,
          autoHideDelay: 2000,
        })
      }
    },
    removeItem(index) {
      this.formData.share_rate.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      setTimeout(() => {
        this.$nextTick(() => {
          setTimeout(() => {
            if (this.$refs.form && this.$refs.form.scrollHeight) {
              this.trSetHeight()
            }
          }, 1000)
        })
      }, 200)
    },
    forceUpdated() {
      this.$forceUpdate()
    },
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const toast = useToast()

    const formData = ref({})

    const timePickerConfig = {
      enableTime: true,
      dateFormat: 'Y-m-d H:i:ss',
      locale: zh.zh, // locale for this instance only
    }

    const productList = reactive([])
    const casePagae = ref(1)
    const total = ref(0)
    const hasPrevPage = computed(() => casePagae.value > 1)
    const hasNextPage = computed(() => casePagae.value < Math.ceil(total.value / 20))

    // 会员列表
    const vipList = reactive([])
    const fetchProdList = () => {
      if (!store.hasModule('product-list')) store.registerModule('product-list', ListManagerModule)
      store
        .dispatch('product-list/fetchDataList', {
          pageSize: 20,
          page: casePagae.value,
        })
        .then(response => {
          productList.push(...response.data.list)
          total.value = response.data.total
        })
    }
    // const cityList = reactive([])

    const itemFormBlankItem = {
      user_id: undefined,
      share_rate: 0,
    }

    const error = reactive({
      text: '',
      component: '',
    })

    onMounted(() => {
      nextTick(() => {
        fetchProdList()
        axiosIns.get('/user/list').then(response => {
          if (response.code === 0) {
            vipList.push(...response.data.list)
          }
        })
        axiosIns.get('/order/create-user').then(response => {
          formData.value.create_name = response.data.real_name
          formData.value.create_id = response.data.id
          formData.value.create_level = response.data.level.name
        })
        const data = proxy.$route.query.id
        axiosIns.get('/order/getOrderInfo', { params: { order_id: data } }).then(response => {
          formData.value = response.data
        })

        if (formData.value && formData.value.id) {
          formData.value.share_rate.forEach(item => {
            item.search_id = item.user_id
            item.create_name = item?.user_info.real_name || item?.create_name
            item.create_level = item?.user_info.level.name
            item.share_rate = item?.share_rate
          })
        }
      })
    })

    const isLoading = ref(false)

    const INVOICE_APP_STORE_MODULE_NAME = 'user-order'
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderModule)

    const submitAddProduct = () => {
      isLoading.value = true
      // proxy.$refs.registerForm.validate().then(isOk => {
      //   if (isOk) {
      //     if (error.text || error.component) {
      //       toast.error(error.text)
      //       isLoading.value = false
      //       return
      //     }
      const editData = {
        product_id: '',
        // status: '1',
        number: '',
        deal_time: '',
        // order_tp: '',
        user_id: '',
        share_rate: [],
        protect_user: '', // 被保人
        sign_city: '', // 签署地
        money: '', // 保单金额
        create_name: '',
        create_id: '',
        create_level: '',
        id: '',
        rate: '',
        except_rate: '',
      }
      Object.keys(editData).forEach(key => {
        if (formData.value[key]) {
          editData[key] = formData.value[key]
        }
      })

      store
        .dispatch('user-order/orderUpdate', {
          ...editData,
        })
        .then(response => {
          if (response.code === 0) {
            toast.success(response.msg || '添加成功')
            setTimeout(() => {
              isLoading.value = false
              proxy.$router.back()
            }, 1000)
          } else {
            isLoading.value = false
            toast.error(response.msg)
          }
        })
        .catch(() => {
          toast.error('添加失败')
          isLoading.value = false
        })
      //   } else {
      //     toast.error('请将表单填写完整')
      //     isLoading.value = false
      //   }
      // })
    }

    const searchId = ref(null)

    const comptry = reactive([])
    const cate = reactive([])
    const c = ref('')
    const d = ref('')

    onMounted(() => {
      axiosIns.get('/pro-brand/getBrandList').then(res => {
        if (res.code === 0) {
          comptry.push(...res.data)
        }
      })
      axiosIns.get('/pro-cate/getCateList').then(res => {
        if (res.code === 0) {
          cate.push(...res.data)
        }
      })
    })

    const yulandis = ref(true)
    const downLoad = () => {
      document.body.style.cursor = 'wait'
      console.log(document.querySelector('.woc').offsetWidth)
      setTimeout(() => {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF()

        html2canvas(document.querySelector('.woc '), {
          height: document.querySelector('.woc').scrollHeight + 300,
          width: document.querySelector('.woc').offsetWidth + 100,
          // height: document.querySelector('.woc').scrollHeight,
          backgroundColor: '#fff',
          scale: 1,
          removeContainer: true,
          allowTaint: true,
          // 背景大小
          bcckgroundSize: 'cover',
        }).then(canvas => {
          const imgData = canvas.toDataURL('image/jpeg')
          doc.addImage(imgData, 'JPEG', 0, 0, 320, 320, '')
          doc.save('invoice.pdf')
          setTimeout(() => {
            document.body.style.cursor = 'default'
          }, 1000)
        })
      }, 1000)
    }

    return {
      yulandis,
      downLoad,
      productList,
      c,
      d,
      comptry,
      cate,
      itemFormBlankItem,
      hasNextPage,
      hasPrevPage,
      formData,
      submitAddProduct,
      isLoading,
      timePickerConfig,
      searchId,
      required,
      error,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
